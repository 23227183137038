module.exports = function () {

	$('.up, .header__logo, .footer__logo').on('click', function(){
		$('html, body').animate({
			scrollTop: 0
		}, 500);
		
		return false;
	});

	$(window).on('scroll', function(){
		if ( $(document).scrollTop() > $(window).height() ) {
			$('.up').addClass('up_visible');
		} else {
			$('.up').removeClass('up_visible');
		}
	}).scroll();

};