require("./../../../bower_components/jQuery-One-Page-Nav/jquery.nav.js");

module.exports = function () {

	$('.nav__list').onePageNav({
		currentClass: 'nav__item_active'
	});

	$('.nav-toggle').on('click', function(){
		$('.nav').addClass('nav_visible');
	});

	$('.nav__close').on('click', function(){
		$('.nav').removeClass('nav_visible');
	});

	$('.nav__link').on('click', function(){
		if ($('.nav').hasClass('nav_visible')) {
			$('.nav').removeClass('nav_visible');
		}
	});

	$(window).on('resize', function(){
		$('.nav_visible').removeClass('nav_visible');
	});

};