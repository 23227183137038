require("./../../../bower_components/parsleyjs/dist/parsley.js");
require("./../../../bower_components/parsleyjs/dist/i18n/ru.js");

module.exports = function() {

	// Feedback popup init
	feedbackPopup();

	var feedbackForm = new SendForm('#feedback-form', 'feedback.php');

	$('#subscribe').on('change', function(){
		if ($(this).prop('checked') === true) {
			$('#feedback-email').prop('required', true);
		} else {
			$('#feedback-email').prop('required', false);
		}
	});

	function feedbackPopup() {
		var $feedback = $('.feedback'),
			$form = $('#feedback-form');

		function show() {
			$feedback.addClass('feedback_visible');
		}

		function hide() {
			$feedback.removeClass('feedback_visible');
			$form[0].reset();
			$form.parsley().reset();
		}

		$('.feedback-init').on('click', function(){
			var $caller = $(this),
				$packageInput = $('#feedback-package'),
				$servicesInput = $('#feedback-services');

			$packageInput.val('');
			$servicesInput.val('');

			if ($caller.attr('data-package')) {
				$packageInput.val($caller.data('package'));
			}

			if ($caller.is('#services-btn')) {
				var services = $('#selection').find('input[name="services"]:checked'),
					servicesValues = [];

				if (services.length > 0) {
					for (var i = 0; i < services.length; i++) {
						servicesValues.push($(services[i]).val());
					}
					$servicesInput.val(servicesValues.join('; '));
				}
			}

			show();
		});

		$('.feedback__close').on('click', function(){
			hide();
		});

		// Close on escape button click
		$(document).keyup(function(e) {
			if (e.keyCode === 27) hide();
		});

	}

	function SendForm(form, scriptURL) {
		var $form = $(form),
			$submitButton = $('#feedback-submit');

		$form.on('submit', function(e){
			var formData = $form.serialize();

			$form.parsley().validate();

			if ($form.parsley().isValid()){
				$submitButton.prop('disabled', true);
				$.ajax({
					type: 'POST',
					url: scriptURL,
					data: formData,
					success: function() {
						$form[0].reset();
						$('.feedback__success').addClass('feedback__success_visible');
						setTimeout(function(){
							$('.feedback__success').removeClass('feedback__success_visible');
							$submitButton.prop('disabled', false);
						}, 5000);
					}
				});
	        }
	        e.preventDefault();
		});
	}
	
};
