module.exports = function () {

	function msieversion() {
	    var ua = window.navigator.userAgent;
	    var msie = ua.indexOf("MSIE ");

	    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
	        $('html').addClass('msie');
	    }
	}

	msieversion();

};