"use strict";
var $ = window.jQuery = window.$ = require("./../../bower_components/jquery/dist/jquery.js");


var initCustomWidgets = function () {
	require('./modules/headerInit')();
	require('./modules/controlsInit')();
	require('./modules/upInit')();
	require('./modules/viewportInit')();
	require('./modules/navInit')();
	require('./modules/msiecheckInit')();
	require('./modules/sendFormInit')();
	// require('./modules/inputMaskInit')();
};

$(initCustomWidgets);
