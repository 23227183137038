module.exports = function () {

	$('.services__control').on('click', function(){
		var $target = $(this),
			$panel = $('#' + $target.data('panel')),
			$toggle = $('.services__toggle');

		if (!$target.hasClass('services__control_active')) {
			$toggle.toggleClass('services__toggle_switched');
			$target.addClass('services__control_active');
			$target.siblings('.services__control').removeClass('services__control_active');
			$panel.addClass('services__panel_active');
			$panel.siblings('.services__panel').removeClass('services__panel_active');
		}
	});

	$('.services__toggle').on('click', function(){
		var $toggle = $(this),
			$oldPanel = $('.services__panel_active'),
			$newPanel = $oldPanel.siblings('.services__panel'),
			$oldControl = $('.services__control_active'),
			$newControl = $oldControl.siblings('.services__control');

		$toggle.toggleClass('services__toggle_switched');
		$newControl.addClass('services__control_active');
		$oldControl.removeClass('services__control_active');
		$newPanel.addClass('services__panel_active');
		$oldPanel.removeClass('services__panel_active');
	});

};