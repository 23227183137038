module.exports = function () {

	$(window).on('scroll', function(){
		if ($(document).scrollTop() > 0) {
			$('.header').addClass('header_floated');
		} else {
			$('.header').removeClass('header_floated');
		}
	}).scroll();

};